<template>
  <div class="PS-screen" v-if="practiceNew != null && practiceNew != undefined">
    <div class="PS-screen-inner">
      <div class="PS-title-box" style="display: flex; justify-content: space-between">
        <p class="PS-title fs-18 fw-700 dark-font">{{ this.testName }} Report</p>
        <div class="PS-title-left">
          <div class="print-page">
            <p class="fw-700 fs-16" @click="printPage()">
              Print <span class="material-icons">print</span>
            </p>
          </div>
          <div class="close-button" @click="goBack()">
            <img
              :src="require(`../assets/cross-button.svg`)"
              alt=""
              style="height: 12px; width: 12px; margin-top: 9px"
            />
          </div>
        </div>
      </div>

      <!-- Practice Session Report top section consisting of 3 columns -->
      <div class="PS-top">
        <div class="base-box summary-box" v-if="practiceNew.SessionSummary != null">
          <p class="fs-16 fw-700 purple-text base-box-title">Summary</p>
          <div class="summary-content">
            <div class="SC-left">
              <p class="fs-13 fw-700 dark-font">
                <span class="bullet skipped-bullet"></span>Skipped:
                {{ practiceNew.SessionSummary.Skipped ? practiceNew.SessionSummary.Skipped : "0" }}
              </p>
              <p class="fs-13 fw-700 dark-font">
                <span class="bullet correct-bullet"></span>Correct:
                {{ practiceNew.SessionSummary.Correct ? practiceNew.SessionSummary.Correct : "0" }}
                <span class="time-light-span fs-11 fw-400 light-font"
                  ><img :src="require(`../assets/time-icon-light.svg`)" alt="" />{{
                    minuteSecondHandler(
                      practiceNew.SessionSummary.CorrectTime
                        ? practiceNew.SessionSummary.CorrectTime
                        : "0",
                    )
                  }}</span
                >
              </p>
              <p class="fs-13 fw-700 dark-font">
                <span class="bullet incorrect-bullet"></span>Incorrect:
                {{ practiceNew.SessionSummary.Wrong ? practiceNew.SessionSummary.Wrong : "0" }}
                <span class="time-light-span fs-11 fw-400 light-font"
                  ><img :src="require(`../assets/time-icon-light.svg`)" alt="" />{{
                    minuteSecondHandler(
                      practiceNew.SessionSummary.IncorrectTime
                        ? practiceNew.SessionSummary.IncorrectTime
                        : "0",
                    )
                  }}</span
                >
              </p>
              <p class="fs-13 fw-700 dark-font">
                <span class="bullet revision-bullet"></span>Revision:
                {{
                  practiceNew.SessionSummary.Revision ? practiceNew.SessionSummary.Revision : "0"
                }}
              </p>
              <p class="fs-13 fw-700 dark-font">
                <span class="bullet clarification-bullet"></span>Clarification:
                {{
                  practiceNew.SessionSummary.Clarification
                    ? practiceNew.SessionSummary.Clarification
                    : "0"
                }}
              </p>
            </div>
            <div class="vc-donut-box">
              <vc-donut
                :sections="sections"
                :thickness="30"
                :size="donutWidthHandler"
                :unit="'%'"
                class="vc-cdc"
              >
                <div class="donut-content">
                  <div>
                    <p class="fw-400 dark-font">
                      Attempted <br />
                      <span class="fw-600 dark-font">{{
                        practiceNew.SessionSummary.TotalAttempted
                          ? practiceNew.SessionSummary.TotalAttempted
                          : "0"
                      }}</span>
                    </p>
                  </div>
                  <div class="DC-bottom">
                    <div>
                      <p class="dark-font">
                        Your Accuracy <br />
                        <span class="fw-600 blue-font"
                          >{{
                            Math.round(
                              (practiceNew.SessionSummary.Correct /
                                practiceNew.SessionSummary.TotalAttempted) *
                                100,
                            )
                              ? Math.round(
                                  (practiceNew.SessionSummary.Correct /
                                    practiceNew.SessionSummary.TotalAttempted) *
                                    100,
                                )
                              : "0"
                          }}<span style="font-size: 13px !important">%</span></span
                        >
                      </p>
                    </div>
                    <hr class="donutHr" />
                    <div>
                      <p class="fw-400 dark-font">
                        Global Accuracy <br />
                        <span class="fw-600 dark-font"
                          >{{
                            practiceNew.SessionSummary.GlobalAccuracy
                              ? practiceNew.SessionSummary.GlobalAccuracy
                              : "0"
                          }}<span style="font-size: 13px !important">%</span></span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </vc-donut>
              <p class="fs-15 fw-600 dark-font PS-time">
                <img :src="require(`../assets/time-icon.svg`)" alt="" />
                {{
                  minuteSecondHandler(
                    practiceNew.SessionSummary.TotalTime
                      ? practiceNew.SessionSummary.TotalTime
                      : "0",
                  )
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="base-box highlights-box invisible-scroll">
          <p style="margin-bottom: 10px" class="fs-16 fw-700 purple-text base-box-title">
            Highlights
          </p>
          <div
            style="margin-bottom: 10px"
            v-for="(question, index) in practiceNew.PromptsList"
            :key="index"
          >
            <Prompts :isPromptVisible="true" :selectedPromptOption="Number(question)"></Prompts>
          </div>
        </div>
        <div class="base-box subject-box" v-if="practiceNew.UserSubjectMetrics != null">
          <p class="fs-16 fw-700 purple-text base-box-title">
            {{ practiceNew.UserSubjectMetrics.SubjectName }}
          </p>
          <div class="sub-content">
            <div class="gauge-chart-box">
              <div style="position: absolute; width: 100%; height: 100%; top: -50px">
                <canvas id="myChart" width="100" height="100"></canvas>
              </div>
              <div>
                <!-- <p class="guage-value fs-22 fw-700 green-font">
                                {{practiceNew.UserSubjectMetrics.Accuracy ? Math.round(practiceNew.UserSubjectMetrics.Accuracy * 100) : ""}} %<br> <span class="fs-13 fw-400">Accuracy</span></p>
                                <p class="fs-13 fw-400 light-font">Your Level</p> -->
                <p class="guage-value fs-24 fw-700" :style="{ color: this.guageColor }">
                  <span class="fs-10 fw-400 light-font">Your Level</span> <br />{{
                    practiceNew.UserSubjectMetrics.ExpertiseText
                  }}
                </p>
              </div>
            </div>
            <div class="AQ-box sub-inner-box">
              <p class="fs-13 fw-400 dark-font text-center">
                Total Attempted <br />
                <span class="fs-16 fw-700">{{
                  practiceNew.UserSubjectMetrics.Attempted
                    ? practiceNew.UserSubjectMetrics.Attempted
                    : ""
                }}</span>
              </p>
            </div>
            <div class="AQ-box sub-inner-box">
              <p class="fs-13 fw-400 dark-font text-center">
                Concepts Covered <br />
                <span class="fs-16 fw-700">{{
                  practiceNew.UserSubjectMetrics.KSCsCovered
                    ? practiceNew.UserSubjectMetrics.KSCsCovered
                    : ""
                }}</span>
              </p>
            </div>
            <div style="gap: 10px; padding: 0" class="flex sub-inner-box">
              <div class="AT-box" style="width: 50%; height: 100%; padding: 15px 0">
                <p class="fs-13 fw-400 dark-font text-center">
                  Accuracy <br />
                  <span class="fs-16 fw-700"
                    >{{
                      practiceNew.UserSubjectMetrics.Accuracy
                        ? Math.round(practiceNew.UserSubjectMetrics.Accuracy * 100)
                        : ""
                    }}%</span
                  >
                </p>
              </div>
              <div class="AT-box" style="width: 50%; height: 100%; padding: 15px 0">
                <p class="fs-13 fw-400 dark-font text-center">
                  Average Time <br />
                  <span class="fs-16 fw-700">{{
                    practiceNew.UserSubjectMetrics.AvgTime
                      ? minuteSecondHandler(practiceNew.UserSubjectMetrics.AvgTime)
                      : ""
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="PS-bottom-oriantation">
        <div class="PS-bottom base-box">
          <div style="overflow-x: auto">
            <div class="responsive-mobile relative">
              <div class="PS-bottom-header">
                <div
                  class="header-item"
                  @click="selectQuestions(0)"
                  :class="selectQuestionsActiveTab === 0 ? 'active-select-question' : ''"
                >
                  <img :src="require(`../assets/all-question-icon.svg`)" alt="" />
                  <p class="fs-14 fw-700 blue-font">
                    All Question <br /><span class="fs-12 fw-600">{{
                      questionsLength.allQuestions
                    }}</span>
                  </p>
                </div>
                <div
                  class="header-item"
                  @click="selectQuestions(1)"
                  :class="{
                    'active-select-question': selectQuestionsActiveTab === 1,
                    'btn-disabled': questionsLength.correct === '0',
                  }"
                >
                  <img :src="require(`../assets/correct-icon.svg`)" alt="" />
                  <p class="fs-14 fw-700 green-font">
                    Correct <br /><span class="fs-12 fw-600">{{ questionsLength.correct }}</span>
                  </p>
                </div>
                <div
                  class="header-item"
                  @click="selectQuestions(2)"
                  :class="{
                    'active-select-question': selectQuestionsActiveTab === 2,
                    'btn-disabled': questionsLength.inCorrect === '0',
                  }"
                >
                  <img :src="require(`../assets/incorrect-icon.svg`)" alt="" />
                  <p class="fs-14 fw-700 red-font">
                    Incorrect <br /><span class="fs-12 fw-600">{{
                      questionsLength.inCorrect
                    }}</span>
                  </p>
                </div>
                <div
                  class="header-item"
                  @click="selectQuestions(3)"
                  :class="{
                    'active-select-question': selectQuestionsActiveTab === 3,
                    'btn-disabled': questionsLength.skipped === '0',
                  }"
                >
                  <img :src="require(`../assets/skipped-icon.svg`)" alt="" />
                  <p class="fs-14 fw-700 dark-gray-font">
                    Skipped <br /><span class="fs-12 fw-600">{{ questionsLength.skipped }}</span>
                  </p>
                </div>
                <div
                  class="header-item"
                  @click="selectQuestions(4)"
                  :class="{
                    'active-select-question': selectQuestionsActiveTab === 4,
                    'btn-disabled': questionsLength.revision === '0',
                  }"
                >
                  <img :src="require(`../assets/revision-icon.svg`)" alt="" />
                  <p class="fs-14 fw-700 light-purple-font">
                    Revisions <br /><span class="fs-12 fw-600">{{ questionsLength.revision }}</span>
                  </p>
                </div>
                <div
                  class="header-item"
                  @click="selectQuestions(5)"
                  :class="{
                    'active-select-question': selectQuestionsActiveTab === 5,
                    'btn-disabled': questionsLength.clarification === '0',
                  }"
                >
                  <img :src="require(`../assets/clarification-icon.svg`)" alt="" />
                  <p class="fs-14 fw-700 sky-blue-font">
                    Clarification <br /><span class="fs-12 fw-600">{{
                      questionsLength.clarification
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="PS-bottom-body" :class="{ 'mobile-filter-body': !showMobileFilters }">
                <div class="filter-sidebar">
                  <p class="filter-heading fs-16 fw-700 dark-font">Filter</p>
                  <div class="sidebar-content">
                    <div class="filter-item filter-chapter">
                      <div style="border-bottom: 1px solid #e0e4f0">
                        <CommonFilterComponent
                          id="chapterFilter"
                          :entityDataObject="this.chapterFilterObject"
                          @selectedEntityId="getSelectedChapterData($event)"
                          v-if="
                            'Name' in this.chapterFilterObject &&
                            'entityArray' in this.chapterFilterObject
                          "
                        />
                      </div>
                      <div>
                        <CommonFilterComponent
                          :entityDataObject="this.conceptFilterObject"
                          @selectedEntityId="getSelectedConceptId($event)"
                          v-if="
                            'Name' in this.conceptFilterObject &&
                            'entityArray' in this.conceptFilterObject
                          "
                        />
                      </div>
                      <div class="difficultlevel" style="border-top: 1px solid #e0e4f0">
                        <p
                          class="flex flex-between diffLevelTitle pointer"
                          @click="isDiffLevelFilterShow = !isDiffLevelFilterShow"
                        >
                          Difficulty<i class="material-icons materialIcon pointer">{{
                            isDiffLevelFilterShow ? "expand_less" : "expand_more"
                          }}</i>
                        </p>
                        <div class="diffGrpButtons" v-if="isDiffLevelFilterShow">
                          <div class="flex">
                            <div
                              class="dark-text pointer difficultylevelTitle difficultyLevelButton all-btn"
                              :class="[
                                {
                                  active:
                                    difficultyFilter.length === tempDifficulty.length &&
                                    difficultyFilter.length > 0,
                                },
                              ]"
                              @click="getCommonQDiffLevel('all')"
                            >
                              ALL
                            </div>
                          </div>
                          <div
                            style="display: flex; flex-wrap: wrap; gap: 5px; padding-right: 15px"
                          >
                            <button
                              class="difficultyLevelButton"
                              v-for="(diffData, index) in diffiGrpData"
                              :key="index"
                              @click="getCommonQDiffLevel(diffData.DifficultyGroup)"
                              :disabled="diffData.DifficultyGroupId === 0"
                              :style="{
                                'background-color': difficultyFilter.includes(
                                  diffData.DifficultyGroup,
                                )
                                  ? diffData.color
                                  : '',
                                color: difficultyFilter.includes(diffData.DifficultyGroup)
                                  ? '#ffff'
                                  : '',
                              }"
                            >
                              <span>{{ diffData.DifficultyGroup }}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="question-section hide-on-mobile">
                  <div class="QS-header">
                    <div style="display: flex; flex: 1 1 5%; max-width: 5%"></div>
                    <div
                      style="display: flex; flex: 1 1 35%; justify-content: center; max-width: 35%"
                    >
                      <p>Question</p>
                    </div>
                    <div
                      style="
                        display: flex;
                        flex: 1 1 25%;
                        justify-content: flex-end;
                        max-width: 25%;
                      "
                    >
                      <p>Concepts <span></span></p>
                    </div>
                    <div
                      style="
                        display: flex;
                        flex: 1 1 15%;
                        justify-content: flex-end;
                        max-width: 15%;
                      "
                    >
                      <p>Your Answer</p>
                    </div>
                    <div
                      style="display: flex; flex: 1 1 10%; justify-content: center; max-width: 10%"
                    >
                      <p>Difficulty</p>
                    </div>
                    <div
                      style="display: flex; flex: 1 1 10%; justify-content: flex-end; max-width: 5%"
                    >
                      <p>Time</p>
                    </div>
                  </div>
                  <div class="question-list">
                    <div class="question-list-inner">
                      <div
                        class="question-list-item clarification-element"
                        v-for="(question, index) in practiceNew.QuestionList"
                        :key="index"
                        @mouseleave="isThreeDotContentActive = false"
                        :class="{ 'border-none': this.UserIdStore !== this.userId }"
                      >
                        <div style="display: flex; flex: 1 1 5%; max-width: 5%">
                          <p class="fs-15 fw-600 black-font">{{ question.RowIndex }}</p>
                        </div>
                        <div
                          v-if="question.Q_LATEX_Status === 'Y'"
                          class="overflow-text"
                          style="height: 47px; flex: 1 1 35%; max-width: 35%; cursor: pointer"
                          @click="showQuestionModal(question.QuestionId, this.userId)"
                        >
                          <vue-mathjax
                            :formula="question.Q_LATEX"
                            :options="VueMathJaxOptions"
                            :safe="false"
                            class="space-break fs-13 fw-500"
                          />
                          <div
                            v-if="
                              question.Q_LATEX_Status === 'Y' &&
                              question.AnswerOption_A &&
                              question.AnswerOption_B &&
                              question.AnswerOption_C &&
                              question.AnswerOption_D
                            "
                          >
                            <h1 class="flex fs-18 fw-600 dark-text m-0 mt-5 space-break">
                              <span v-if="!question.AnswerOption_A.includes('(A)')">(A)</span>
                              <vue-mathjax
                                :formula="question.AnswerOption_A"
                                :options="VueMathJaxOptions"
                                :safe="false"
                              />
                            </h1>
                            <h1 class="flex fs-18 fw-600 dark-text m-0 mt-5 space-break">
                              <span v-if="!question.AnswerOption_B.includes('(B)')">(B)</span>
                              <vue-mathjax
                                :formula="question.AnswerOption_B"
                                :options="VueMathJaxOptions"
                                :safe="false"
                              />
                            </h1>
                            <h1 class="flex fs-18 fw-600 dark-text m-0 mt-5 space-break">
                              <span v-if="!question.AnswerOption_C.includes('(C)')">(C)</span>
                              <vue-mathjax
                                :formula="question.AnswerOption_C"
                                :options="VueMathJaxOptions"
                                :safe="false"
                              />
                            </h1>
                            <h1 class="flex fs-18 fw-600 dark-text m-0 mt-5 space-break">
                              <span v-if="!question.AnswerOption_D.includes('(D)')">(D)</span>
                              <vue-mathjax
                                :formula="question.AnswerOption_D"
                                :options="VueMathJaxOptions"
                                :safe="false"
                              />
                            </h1>
                          </div>
                        </div>
                        <div
                          v-else
                          style="flex: 1 1 35%; max-width: 35%; cursor: pointer"
                          @click="showQuestionModal(question.QuestionId, this.userId)"
                        >
                          <img :src="question.QuestionDiagramURL" alt="" draggable="false" />
                        </div>
                        <div style="display: flex; flex: 1 1 25%">
                          <p class="fs-13 fw-600 dark-font conceptText-truncate">
                            {{ question.KSCClusterName }}
                          </p>
                        </div>
                        <div
                          style="
                            display: flex;
                            flex: 1 1 15%;
                            justify-content: center;
                            max-width: 15%;
                            gap: 10px;
                          "
                        >
                          <div>
                            <img
                              :src="require(`../assets/skipped-icon.svg`)"
                              alt=""
                              v-if="question.Skipped === 1"
                              class="question-status-icon"
                            />
                            <!-- <img :src="require(`../assets/revision-icon.svg`)" alt="" v-if="question.markForRevision === 1" class="question-status-icon">
                                                    <img :src="require(`../assets/clarification-icon.svg`)" alt="" v-if="question.markForClarification === 1" class="question-status-icon"> -->
                            <img
                              :src="require(`../assets/incorrect-icon.svg`)"
                              alt=""
                              v-if="question.Incorrect === 1"
                              class="question-status-icon"
                            />
                            <img
                              :src="require(`../assets/correct-icon.svg`)"
                              alt=""
                              v-if="question.Correct === 1"
                              class="question-status-icon"
                            />
                          </div>
                          <p class="QLI-answer fs-13 fw-600">
                            {{ question.AnswerOption }}
                          </p>
                        </div>
                        <div
                          style="
                            display: flex;
                            flex: 1 1 10%;
                            justify-content: center;
                            max-width: 10%;
                          "
                        >
                          <div
                            v-if="question.Difficulty === 'Basic'"
                            class="difficulty-btn fs-11 fw-600 basic-btn"
                          >
                            Basic
                          </div>

                          <div
                            v-if="question.Difficulty === 'Easy'"
                            class="difficulty-btn fs-11 fw-600 easy-btn"
                          >
                            Easy
                          </div>

                          <div
                            v-if="question.Difficulty === 'Normal'"
                            class="difficulty-btn fs-11 fw-600 normal-btn"
                          >
                            Normal
                          </div>

                          <div
                            v-if="question.Difficulty === 'Hard'"
                            class="difficulty-btn fs-11 fw-600 hard-btn"
                          >
                            Hard
                          </div>

                          <div
                            v-if="question.Difficulty === 'Tough'"
                            class="difficulty-btn fs-11 fw-600 tough-btn"
                          >
                            Tough
                          </div>
                        </div>
                        <div
                          style="
                            display: flex;
                            flex: 1 1 10%;
                            justify-content: flex-end;
                            max-width: 10%;
                          "
                        >
                          <p class="fs-13 fw-600 dark-font" v-if="question.TimeTaken !== 0">
                            {{ minuteSecondHandler(question.TimeTaken) }}
                          </p>
                        </div>
                        <div
                          class="three-dot-icon"
                          @click="threeDotHandler(index)"
                          v-if="this.UserIdStore === this.userId"
                        >
                          <img :src="require(`../assets/three-dot-icon.svg`)" alt="" />
                        </div>
                        <div
                          class="three-dot-content"
                          v-if="threeDotContentId === index && isThreeDotContentActive"
                        >
                          <div
                            class="add-to-revision three-dot-item"
                            @click="
                              revisionQuestionHandler(
                                question.QuestionId,
                                question.UserTestSessionQuestionId,
                                question.Revision,
                              )
                            "
                          >
                            <img :src="require(`../assets/add-to-revision-icon.svg`)" alt="" />
                            <p class="fw-600 fs-13 blue-font">
                              {{ question.Revision ? "Remove From" : "Add To" }} Revision
                            </p>
                          </div>
                          <div
                            class="mark-for-clarification three-dot-item"
                            :class="{
                              'blue-bg': question.MarkForClarification || isMarkForClarification,
                            }"
                            @click="
                              markForClarificationHandler(
                                question.UserTestSessionQuestionId,
                                question.MarkForClarification,
                              )
                            "
                          >
                            <img
                              :src="require(`../assets/mark-for-clarification-icon.svg`)"
                              alt=""
                              :class="{
                                'white-bg': question.MarkForClarification || isMarkForClarification,
                              }"
                            />
                            <p
                              class="fw-600 fs-13 blue-font"
                              :class="{
                                'white-font':
                                  question.MarkForClarification || isMarkForClarification,
                              }"
                            >
                              {{
                                question.MarkForClarification || isMarkForClarification
                                  ? "Marked"
                                  : "Mark"
                              }}
                              for Clarification
                            </p>
                          </div>
                        </div>
                        <span
                          class="question-color-border basic-btn"
                          v-if="question.Difficulty === 'Basic'"
                        ></span>
                        <span
                          class="question-color-border easy-btn"
                          v-if="question.Difficulty === 'Easy'"
                        ></span>
                        <span
                          class="question-color-border normal-btn"
                          v-if="question.Difficulty === 'Normal'"
                        ></span>
                        <span
                          class="question-color-border hard-btn"
                          v-if="question.Difficulty === 'Hard'"
                        ></span>
                        <span
                          class="question-color-border tough-btn"
                          v-if="question.Difficulty === 'Tough'"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="question-section hide-on-desktop mobile-question-section">
            <div class="QS-header">
              <div>
                <p>Concepts</p>
              </div>
              <div>
                <p>Question</p>
              </div>
              <div>
                <p>Your Answer</p>
              </div>
              <div>
                <p>Difficulty</p>
              </div>
              <div>
                <p>Time</p>
              </div>
            </div>
            <div class="question-list">
              <div class="question-list-inner">
                <div
                  class="question-list-item clarification-element"
                  v-for="(question, index) in practiceNew.QuestionList"
                  :key="index"
                  @mouseleave="isThreeDotContentActive = false"
                  :class="{ 'border-none': this.UserIdStore !== this.userId }"
                >
                  <div class="flex" style="width: 100%">
                    <div style="flex: 1 1 20%; max-width: 5%">
                      <p class="fs-15 fw-600 black-font">{{ question.RowIndex }}</p>
                    </div>
                    <!-- <vue-mathjax v-if="question.Q_LATEX_Status === Y" :formula="question.Q_LATEX" :options="VueMathJaxOptions" :safe="false" class="space-break fs-18 fw-500" /> -->
                    <div
                      v-if="question.Q_LATEX_Status === 'Y'"
                      class="overflow-text"
                      style="height: 47px; flex: 1 1 80%; cursor: pointer"
                      @click="showQuestionModal(question.QuestionId, this.userId)"
                    >
                      <vue-mathjax
                        :formula="question.Q_LATEX"
                        :options="VueMathJaxOptions"
                        :safe="false"
                        class="space-break fs-13 fw-500"
                      />
                      <div
                        v-if="
                          question.Q_LATEX_Status === 'Y' &&
                          question.AnswerOption_A &&
                          question.AnswerOption_B &&
                          question.AnswerOption_C &&
                          question.AnswerOption_D
                        "
                      >
                        <h1 class="flex fs-18 fw-600 dark-text m-0 mt-5 space-break">
                          <span v-if="!question.AnswerOption_A.includes('(A)')">(A)</span>
                          <vue-mathjax
                            :formula="question.AnswerOption_A"
                            :options="VueMathJaxOptions"
                            :safe="false"
                          />
                        </h1>
                        <h1 class="flex fs-18 fw-600 dark-text m-0 mt-5 space-break">
                          <span v-if="!question.AnswerOption_B.includes('(B)')">(B)</span>
                          <vue-mathjax
                            :formula="question.AnswerOption_B"
                            :options="VueMathJaxOptions"
                            :safe="false"
                          />
                        </h1>
                        <h1 class="flex fs-18 fw-600 dark-text m-0 mt-5 space-break">
                          <span v-if="!question.AnswerOption_C.includes('(C)')">(C)</span>
                          <vue-mathjax
                            :formula="question.AnswerOption_C"
                            :options="VueMathJaxOptions"
                            :safe="false"
                          />
                        </h1>
                        <h1 class="flex fs-18 fw-600 dark-text m-0 mt-5 space-break">
                          <span v-if="!question.AnswerOption_D.includes('(D)')">(D)</span>
                          <vue-mathjax
                            :formula="question.AnswerOption_D"
                            :options="VueMathJaxOptions"
                            :safe="false"
                          />
                        </h1>
                      </div>
                    </div>
                    <div
                      v-else
                      style="flex: 1 1 80%; cursor: pointer; cursor: pointer"
                      @click="showQuestionModal(question.QuestionId, this.userId)"
                    >
                      <img :src="question.QuestionDiagramURL" alt="" draggable="false" />
                    </div>
                  </div>
                  <div
                    class="flex"
                    style="width: 100%; align-items: center; justify-content: space-between"
                  >
                    <div style="flex: 1 1 25%; max-width: 25%">
                      <p class="fs-11 fw-600 dark-font conceptText-truncate">
                        {{ textEllipsis(question.KSCClusterName) }}
                      </p>
                    </div>
                    <div
                      style="
                        display: flex;
                        flex: 1 1 25%;
                        justify-content: center;
                        gap: 10px;
                        max-width: 25%;
                      "
                    >
                      <div>
                        <img
                          :src="require(`../assets/skipped-icon.svg`)"
                          alt=""
                          v-if="question.Skipped === 1"
                          class="question-status-icon"
                        />
                        <!-- <img :src="require(`../assets/revision-icon.svg`)" alt="" v-if="question.markForRevision === 1" class="question-status-icon">
                                                    <img :src="require(`../assets/clarification-icon.svg`)" alt="" v-if="question.markForClarification === 1" class="question-status-icon"> -->
                        <img
                          :src="require(`../assets/incorrect-icon.svg`)"
                          alt=""
                          v-if="question.Incorrect === 1"
                          class="question-status-icon"
                        />
                        <img
                          :src="require(`../assets/correct-icon.svg`)"
                          alt=""
                          v-if="question.Correct === 1"
                          class="question-status-icon"
                        />
                      </div>
                      <p class="QLI-answer fs-13 fw-600">
                        {{ question.AnswerOption }}
                      </p>
                    </div>
                    <div
                      style="display: flex; flex: 1 1 10%; justify-content: center; max-width: 10%"
                    >
                      <div
                        v-if="question.Difficulty === 'Basic'"
                        class="difficulty-btn fs-11 fw-600 basic-btn"
                      >
                        Basic
                      </div>

                      <div
                        v-if="question.Difficulty === 'Easy'"
                        class="difficulty-btn fs-11 fw-600 easy-btn"
                      >
                        Easy
                      </div>

                      <div
                        v-if="question.Difficulty === 'Normal'"
                        class="difficulty-btn fs-11 fw-600 normal-btn"
                      >
                        Normal
                      </div>

                      <div
                        v-if="question.Difficulty === 'Hard'"
                        class="difficulty-btn fs-11 fw-600 hard-btn"
                      >
                        Hard
                      </div>

                      <div
                        v-if="question.Difficulty === 'Tough'"
                        class="difficulty-btn fs-11 fw-600 tough-btn"
                      >
                        Tough
                      </div>
                    </div>
                    <div
                      style="
                        display: flex;
                        flex: 1 1 10%;
                        justify-content: flex-end;
                        max-width: 10%;
                      "
                    >
                      <p class="fs-13 fw-600 dark-font" v-if="question.TimeTaken !== 0">
                        {{ minuteSecondHandler(question.TimeTaken) }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="three-dot-icon"
                    @click="threeDotHandler(index)"
                    v-if="this.UserIdStore === this.userId"
                  >
                    <img :src="require(`../assets/three-dot-icon.svg`)" alt="" />
                  </div>
                  <div
                    class="three-dot-content"
                    v-if="threeDotContentId === index && isThreeDotContentActive"
                  >
                    <div
                      class="add-to-revision three-dot-item"
                      @click="
                        revisionQuestionHandler(
                          question.QuestionId,
                          question.UserTestSessionQuestionId,
                          question.Revision,
                        )
                      "
                    >
                      <img :src="require(`../assets/add-to-revision-icon.svg`)" alt="" />
                      <p class="fw-600 fs-13 blue-font">
                        {{ question.Revision ? "Remove From" : "Add To" }} Revision
                      </p>
                    </div>
                    <div
                      class="mark-for-clarification three-dot-item"
                      :class="{
                        'blue-bg': question.MarkForClarification || isMarkForClarification,
                      }"
                      @click="
                        markForClarificationHandler(
                          question.UserTestSessionQuestionId,
                          question.MarkForClarification,
                        )
                      "
                    >
                      <img
                        :src="require(`../assets/mark-for-clarification-icon.svg`)"
                        alt=""
                        :class="{
                          'white-bg': question.MarkForClarification || isMarkForClarification,
                        }"
                      />
                      <p
                        class="fw-600 fs-13 blue-font"
                        :class="{
                          'white-font': question.MarkForClarification || isMarkForClarification,
                        }"
                      >
                        {{
                          question.MarkForClarification || isMarkForClarification
                            ? "Marked"
                            : "Mark"
                        }}
                        for Clarification
                      </p>
                    </div>
                  </div>
                  <span
                    class="question-color-border basic-btn"
                    v-if="question.Difficulty === 'Basic'"
                  ></span>
                  <span
                    class="question-color-border easy-btn"
                    v-if="question.Difficulty === 'Easy'"
                  ></span>
                  <span
                    class="question-color-border normal-btn"
                    v-if="question.Difficulty === 'Normal'"
                  ></span>
                  <span
                    class="question-color-border hard-btn"
                    v-if="question.Difficulty === 'Hard'"
                  ></span>
                  <span
                    class="question-color-border tough-btn"
                    v-if="question.Difficulty === 'Tough'"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ViewQuestionModal
      :questionId="questionId"
      :selectedTab="selectedModalTab"
      v-if="showQuesViewModal"
      @closeModal="closeToggle($event)"
    />
  </div>
</template>

<script>
// import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import Chart from "chart.js/auto";
import Mobileapi from "../Mobileapi";
// import Modal2 from "../components/Model2.vue";
import Prompts from "../components/UserPrompt.vue";
import practiceData from "../practiceDummyData.json";
// import DifficultLevelComponent from "../components/DifficultLevelComponent.vue";
import CommonFilterComponent from "../components/CommonFilterComponent.vue";
import ViewQuestionModal from "../components/ViewQuestionModal.vue";

export default {
  data() {
    return {
      sections: [
        {
          value: 0,
          color: "#e8e8e8",
        },
        {
          value: 0,
          color: "#00B894",
        },
        {
          value: 0,
          color: "#FF7675",
        },
      ],
      VueMathJaxOptions: {
        extensions: ["tex2jax.js"],
        jax: ["input/TeX", "output/HTML-CSS"],
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: true,
          processEnvironments: true,
        },
        "HTML-CSS": {
          fonts: ["TeX"],
        },
      },
      showQuesViewModal: false,
      isChapterFilterShow: true,
      isConceptFilterShow: true,
      isDifficultyFilterShow: true,
      getSessionReport: [],
      skippedTotal: null,
      correctTotal: null,
      inCorrectTotal: null,
      revisionTotal: null,
      clarificationTotal: null,
      reportData: "",
      isAllChecked: true,
      selectedQuestionList: "",
      tempSelectedQuestionList: "",
      chapterFilter: "",
      conceptFilter: "",
      difficultyFilter: [],
      tempDifficulty: [],
      gaugeDataAccuracy: null,
      gauageAccuracyPercentage: "",
      conceptModal: false,
      questionModal: false,
      isThreeDotContentActive: false,
      threeDotContentId: -1,
      conceptTabActive: 0,
      //   this is for the modal
      QuestionDiagramURL: "",
      //   this for the question list
      QuestionImageURL: "",
      FullSolutionURL: "",
      kscDiagramUrl: "",
      KscDiagrams: [],
      skippedPercentage: "",
      correctPercentage: "",
      incorrectPercentage: "",
      revisionPercentage: "",
      clarificationPercentage: "",
      FullSolutionVideoUrl: "",
      selectedModalTab: 1,
      selectQuestionsActiveTab: 0,
      entireQuestionDetailArray: [],
      userId: null,
      userTestSessionId: null,
      correctTotalTime: null,
      incorrectTotalTime: null,
      sessionTime: null,
      userSubjectAttempted: null,
      userSubjectAvgTime: null,
      allQuestionLength: null,
      subjectName: "",
      conceptsCovered: 0,
      selectedCourseChapterId: null,
      selectedConcept: null,
      selectedDifficulty: null,
      promptsList: [],
      showMobileFilters: false,
      SLToken: "",
      userTestSessionQuestionId: 0,
      questionId: 0,
      sourceTypeId: 0,
      createdBy: 0,
      selectedConceptId: -1,
      selectedDifficultyGrp: "All",
      practiceNew: practiceData,
      questionsLength: {
        correct: 0,
        inCorrect: 0,
        skipped: 0,
        revision: 0,
        clarification: 0,
        allQuestions: 0,
      },
      clonedOriginalList: [],
      conceptFilterObject: {},
      chapterFilterObject: {},
      selectedDifficultyLevels: "",
      selectedChapters: "",
      selectedConcepts: "",
      markForRevisionQuestionId: null,
      isMarkedForRevision: false,
      isMarkForClarification: false,
      UserIdStore: null,
      diffiGrpData: [
        {
          DifficultyGroup: "Basic",
          DifficultyGroupId: 0,
          color: "#16A085",
          isSelected: false,
        },
        {
          DifficultyGroup: "Easy",
          DifficultyGroupId: 0,
          color: "#8BC32E",
          isSelected: false,
        },
        {
          DifficultyGroup: "Normal",
          DifficultyGroupId: 0,
          color: "#FFBF44",
          isSelected: false,
        },
        {
          DifficultyGroup: "Hard",
          DifficultyGroupId: 0,
          color: "#FF835CE5",
          isSelected: false,
        },
        {
          DifficultyGroup: "Tough",
          DifficultyGroupId: 0,
          color: "#B03E3E",
          isSelected: false,
        },
      ],
      isDiffLevelFilterShow: true,
      totalQuestionList: [],
      guageColor: "",
      testName: "",
    };
  },
  components: {
    Prompts,
    // DifficultLevelComponent,
    CommonFilterComponent,
    ViewQuestionModal,
  },
  created() {
    this.subjectName = this.$route.params.SubjectName;
    this.userTestSessionId = this.$route.params.UserTestSessionId;
    this.testName = this.$route.params.TestName;
    // console.log(this.testName);
    if (this.testName) {
      this.testName = this.$route.params.TestName;
      localStorage.setItem("TestName", this.testName);
    } else {
      this.testName = localStorage.getItem("TestName");
    }
    // this.userTestSessionId = 1226668;
    // console.log(this.userTestSessionId);
    if (this.userTestSessionId) {
      this.userTestSessionId = this.$route.params.UserTestSessionId;
      localStorage.setItem("UserTestSessionId", this.userTestSessionId);
      this.subjectName = this.$route.params.SubjectName;
      localStorage.setItem("SubjectName", this.subjectName);
    } else {
      this.userTestSessionId = Number(localStorage.getItem("UserTestSessionId"));
      this.subjectName = localStorage.getItem("SubjectName");
    }
    if (localStorage && localStorage.getItem("SLToken")) {
      this.SLToken = localStorage.getItem("SLToken") || "";
    }
    Mobileapi.getPracticeReportNew(this.userTestSessionId, (response) => {
      if (response.responseCode === 204) {
        this.$router.push({
          name: "PracticeHome",
        });
      }
      if (response.responseCode === 200) {
        this.practiceNew = response.data;
        this.clonedOriginalList = this.practiceNew.QuestionList;
        this.questionsLength.correct =
          this.practiceNew.QuestionList.filter((a) => Number(a.Correct) === 1).length > 0
            ? this.practiceNew.QuestionList.filter((a) => Number(a.Correct) === 1).length
            : "0";
        this.questionsLength.inCorrect =
          this.practiceNew.QuestionList.filter((a) => Number(a.Incorrect) === 1).length > 0
            ? this.practiceNew.QuestionList.filter((a) => Number(a.Incorrect) === 1).length
            : "0";
        this.questionsLength.skipped =
          this.practiceNew.QuestionList.filter((a) => Number(a.Skipped) === 1).length > 0
            ? this.practiceNew.QuestionList.filter((a) => Number(a.Skipped) === 1).length
            : "0";
        this.questionsLength.revision =
          this.practiceNew.QuestionList.filter((a) => Number(a.Revision) === 1).length > 0
            ? this.practiceNew.QuestionList.filter((a) => Number(a.Revision) === 1).length
            : "0";
        this.questionsLength.clarification =
          this.practiceNew.QuestionList.filter((a) => Number(a.MarkForClarification) === 1).length >
          0
            ? this.practiceNew.QuestionList.filter((a) => Number(a.MarkForClarification) === 1)
                .length
            : "0";
        this.questionsLength.allQuestions =
          this.practiceNew.QuestionList.length > 0 ? this.practiceNew.QuestionList.length : "0";
        this.userId = this.practiceNew.SessionSummary.UserId;
        this.UserIdStore = this.$store.getters.user.UserId;
        this.donutChartHandler();
        if (this.practiceNew.QuestionList.length > 0) {
          this.filterHandler();
        }
      }
    });
  },
  mounted() {
    setTimeout(() => {
      this.guageCharthandler();
    }, 3000);
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F6F8FB");
  },
  beforeUnmount() {
    localStorage.removeItem("UserTestSessionId");
    localStorage.removeItem("TestName");
  },
  computed: {
    donutWidthHandler() {
      // eslint-disable-next-line no-restricted-globals
      const screenWidth = screen.width;
      if (screenWidth > 380) {
        return 75;
      }
      return 100;
    },
  },
  methods: {
    getCommonQDiffLevel(diffleveldata) {
      //   console.log(diffleveldata);
      if (
        diffleveldata !== undefined &&
        diffleveldata != null &&
        diffleveldata !== "" &&
        diffleveldata !== "all"
      ) {
        if (this.difficultyFilter.includes(diffleveldata)) {
          const index = this.difficultyFilter.indexOf(diffleveldata);
          if (index > -1) {
            this.difficultyFilter.splice(index, 1);
          }
        } else {
          this.difficultyFilter.push(diffleveldata);
        }
      } else {
        this.practiceNew.QuestionList = this.totalQuestionList;
        this.diffiGrpData = this.diffiGrpData.map((item) => ({
          ...item,
          DifficultyGroupId: 0,
        }));
        const diffFilterObj = this.practiceNew.QuestionList.map((item) => item.Difficulty);
        this.difficultyFilter = [...new Set(diffFilterObj)];
        this.tempDifficulty = [...new Set(diffFilterObj)];
        this.difficultyFilter.forEach((item2) => {
          this.diffiGrpData = this.diffiGrpData.map((item) => {
            if (item.DifficultyGroup === item2 && item.DifficultyGroupId === 0) {
              return {
                ...item,
                DifficultyGroupId: 1,
              };
            }
            return {
              ...item,
            };
          });
        });
      }
      this.filterData(this.difficultyFilter, this.selectedChapters, this.selectedConcepts);
    },
    getSelectedChapterData(chapterData) {
      this.selectedChapters = chapterData;
      this.filterData(this.difficultyFilter, this.selectedChapters, this.selectedConcepts);
    },
    getSelectedConceptId(conceptData) {
      this.selectedConcepts = conceptData;
      this.filterData(this.difficultyFilter, this.selectedChapters, this.selectedConcepts);
    },
    filterData(diffleveldata, chapterData, conceptData) {
      let selectedQns = this.totalQuestionList;
      if (
        chapterData === undefined ||
        chapterData === null ||
        chapterData.length <= 0 ||
        conceptData === undefined ||
        conceptData === null ||
        conceptData.length <= 0 ||
        diffleveldata === undefined ||
        diffleveldata === null ||
        diffleveldata.length <= 0
      ) {
        this.practiceNew.QuestionList = [];
      } else {
        if (chapterData !== undefined && chapterData != null && chapterData.length > 0) {
          selectedQns = selectedQns.filter((f) =>
            chapterData.some((item) => Number(item) === Number(f.CourseChapterId)),
          );
        }
        if (conceptData !== undefined && conceptData != null && conceptData.length > 0) {
          selectedQns = selectedQns.filter((f) =>
            conceptData.some((item) => Number(item) === Number(f.KSCId)),
          );
        }
        if (diffleveldata.length > 0) {
          selectedQns = selectedQns.filter((f) =>
            diffleveldata.some((item) => item === f.Difficulty),
          );
        }
        this.practiceNew.QuestionList = selectedQns;
      }
    },
    donutChartHandler() {
      if (this.practiceNew) {
        this.skippedPercentage = this.practiceNew.SessionSummary.SkippedPct;
        this.correctPercentage = this.practiceNew.SessionSummary.CorrectPct;
        this.incorrectPercentage = this.practiceNew.SessionSummary.IncorrectPct;
        this.sections = [
          {
            value: this.skippedPercentage,
            color: "#e8e8e8",
          },
          {
            value: this.correctPercentage,
            color: "#00B894",
          },
          {
            value: this.incorrectPercentage,
            color: "#FF7675",
          },
        ];
      }
    },
    guageCharthandler() {
      const ctx = document.getElementById("myChart");
      const gaugeDataAccuracy = Math.floor(this.practiceNew.UserSubjectMetrics.Expertise * 100);
      this.guageColorhandler(gaugeDataAccuracy);
      const data = {
        datasets: [
          {
            label: false,
            data: [Math.floor(gaugeDataAccuracy), Math.floor(100 - gaugeDataAccuracy)],
            value: 100,
            backgroundColor: [this.guageColor, "#F3F5FC"],
            // hoverOffset: 4,
            rotation: 270,
            circumference: 180,
          },
        ],
      };
      const myChart = new Chart(ctx, {
        type: "doughnut",
        data,
        options: {
          plugins: {
            tooltip: {
              intersect: false,
              enabled: false,
            },
          },
        },
      });
      // eslint-disable-next-line no-unused-expressions
      myChart;
    },
    textEllipsis(input) {
      if (input.length > 5) {
        return `${input.substring(0, 20)}...`;
      }
      return input;
    },
    guageColorhandler(value) {
      if (value <= 20) {
        this.guageColor = "#B03E3E";
      }
      if (value > 20 && value <= 40) {
        this.guageColor = "#FF835C";
      }
      if (value > 40 && value <= 60) {
        this.guageColor = "#FFBF44";
      }
      if (value > 60 && value <= 80) {
        this.guageColor = "#8BC42E";
      }
      if (value > 80 && value <= 100) {
        this.guageColor = "#16A085";
      }
    },
    activeTabHandler() {
      if (this.selectQuestionsActiveTab === 0) {
        this.selectQuestions(0);
      }
      if (this.selectQuestionsActiveTab === 1) {
        this.selectQuestions(1);
      }
      if (this.selectQuestionsActiveTab === 2) {
        this.selectQuestions(2);
      }
      if (this.selectQuestionsActiveTab === 3) {
        this.selectQuestions(3);
      }
      if (this.selectQuestionsActiveTab === 4) {
        this.selectQuestions(4);
      }
      if (this.selectQuestionsActiveTab === 5) {
        this.selectQuestions(5);
      }
    },
    goBack() {
      if (this.testName === "EffortAnalysis") {
        this.$router.replace({ name: "EffortAnalysisNew" });
      } else if (this.testName === "ParcticeAnalysis") {
        this.$router.replace({
          name: "PracticeAnalysisUserReport",
          params: { Source: 1 },
        });
      } else if (this.testName === "PracticeHistory") {
        this.$router.replace({ name: "PracticeHistory" });
      } else {
        this.$router.replace({ name: "PracticeHome" });
      }
    },
    selectQuestions(option) {
      this.difficultyFilter = [];
      switch (Number(option)) {
        case 0:
          this.practiceNew.QuestionList = this.clonedOriginalList;
          this.tempSelectedQuestionList = this.selectedQuestionList;
          this.selectQuestionsActiveTab = 0;
          this.selectedCourseChapterId = -1;
          this.selectedConceptId = -1;
          this.selectedDifficultyGrp = "All";
          break;
        case 1:
          this.practiceNew.QuestionList = this.clonedOriginalList.filter(
            (a) => Number(a.Correct) === 1,
          );
          this.tempSelectedQuestionList = this.selectedQuestionList;
          this.selectQuestionsActiveTab = 1;
          this.selectedCourseChapterId = -1;
          this.selectedConceptId = -1;
          this.selectedDifficultyGrp = "All";
          break;
        case 2:
          this.practiceNew.QuestionList = this.clonedOriginalList.filter(
            (a) => Number(a.Incorrect) === 1,
          );
          this.tempSelectedQuestionList = this.selectedQuestionList;
          this.selectQuestionsActiveTab = 2;
          this.selectedCourseChapterId = -1;
          this.selectedConceptId = -1;
          this.selectedDifficultyGrp = "All";
          break;
        case 3:
          this.practiceNew.QuestionList = this.clonedOriginalList.filter(
            (a) => Number(a.Skipped) === 1,
          );
          this.tempSelectedQuestionList = this.selectedQuestionList;
          this.selectQuestionsActiveTab = 3;
          this.selectedCourseChapterId = -1;
          this.selectedConceptId = -1;
          this.selectedDifficultyGrp = "All";
          break;
        case 4:
          this.practiceNew.QuestionList = this.clonedOriginalList.filter(
            (a) => Number(a.Revision) === 1,
          );
          this.tempSelectedQuestionList = this.selectedQuestionList;
          this.selectQuestionsActiveTab = 4;
          this.selectedCourseChapterId = -1;
          this.selectedConceptId = -1;
          this.selectedDifficultyGrp = "All";
          break;
        case 5:
          this.practiceNew.QuestionList = this.clonedOriginalList.filter(
            (a) => Number(a.MarkForClarification) === 1,
          );
          this.tempSelectedQuestionList = this.selectedQuestionList;
          this.selectQuestionsActiveTab = 5;
          this.selectedCourseChapterId = -1;
          this.selectedConceptId = -1;
          this.selectedDifficultyGrp = "All";
          break;
        default:
      }
      this.totalQuestionList = this.practiceNew.QuestionList;
      this.diffiGrpData = this.diffiGrpData.map((item) => ({
        ...item,
        DifficultyGroupId: 0,
      }));
      const diffFilterObj = this.practiceNew.QuestionList.map((item) => item.Difficulty);
      this.difficultyFilter = [...new Set(diffFilterObj)];
      this.tempDifficulty = [...new Set(diffFilterObj)];
      this.difficultyFilter.forEach((item2) => {
        this.diffiGrpData = this.diffiGrpData.map((item) => {
          if (item.DifficultyGroup === item2 && item.DifficultyGroupId === 0) {
            return {
              ...item,
              DifficultyGroupId: 1,
            };
          }
          return {
            ...item,
          };
        });
      });
      this.filterHandler();
    },
    filterHandler() {
      if (this.totalQuestionList.length === 0) {
        this.selectQuestions(this.selectQuestionsActiveTab);
      }
      if (this.practiceNew.QuestionList) {
        const conceptFilter = this.practiceNew.QuestionList.map((item) => ({
          entityName: item.KSCClusterName,
          entityId: item.KSCId,
        }));
        this.conceptFilter = [
          ...new Map(conceptFilter.map((item) => [item.entityName, item])).values(),
        ];
        this.conceptFilterObject = {
          Name: "Concept", // Component Title
          entityArray: this.conceptFilter, // array
        };
        const chapterFilter = this.practiceNew.QuestionList.map((item) => ({
          entityName: item.ChapterName,
          entityId: item.CourseChapterId,
        }));
        this.chapterFilter = [
          ...new Map(chapterFilter.map((item) => [item.entityName, item])).values(),
        ];
        this.chapterFilterObject = {
          Name: "Chapter", // Component Title
          entityArray: this.chapterFilter, // array
        };
      }
    },
    avgTimeHandler(timeInSec) {
      return new Date(timeInSec * 1000).toISOString().substring(14, 19);
    },
    minuteSecondHandler(value) {
      if (value) {
        const hour = Math.floor(value / 3600);
        const minute = Math.floor((value % 3600) / 60);
        const seconds = Math.floor(value % 60) || "";
        // console.log("date", hour, minute, seconds);
        if (value > 59) {
          return (
            (hour >= 1 ? ` ${hour}h` : "") +
            (minute >= 1 ? ` ${minute}m` : "") +
            (seconds >= 1 ? ` ${seconds}s` : "")
          );
        }
        return `${Math.floor(value)}s`;
      }
      return "0s";
    },
    viewConcept(kscDiagrams, index) {
      this.conceptTabActive = index;
      this.kscDiagramUrl = kscDiagrams;
    },
    threeDotHandler(index) {
      this.isThreeDotContentActive = !this.isThreeDotContentActive;
      this.questionModal = false;
      this.threeDotContentId = index;
    },
    closeToggle(event) {
      this.showQuesViewModal = event;
    },
    showQuestionModal(questionId) {
      this.showQuesViewModal = true;
      this.questionId = questionId;
      this.QuestionDiagramURL = "";
      this.selectedModalTab = 1;
      this.questionModal = true;
      this.conceptTabActive = 0;
      //   Mobileapi.getEntireDataOfQuestion({
      //     QuestionId: questionId,
      //     UserId: userId,
      //   }, (response) => {
      //     if (response.responseCode === 200) {
      //       this.entireQuestionDetailArray = response.data;
      //       this.QuestionDiagramURL = this.entireQuestionDetailArray.QuestionDiagramURL;
      //       this.FullSolutionURL = this.entireQuestionDetailArray.FullSolutionURL;
      //       this.KscDiagrams = this.entireQuestionDetailArray.KscDiagrams;
      //       this.kscDiagramUrl = this.KscDiagrams[0];
      //       this.FullSolutionVideoUrl = this.entireQuestionDetailArray.FullSolutionVideoUrl;
      //     }
      //   });
    },
    printPage() {
      window.print();
    },
    markForClarificationHandler(UserTestSessionQuestionId, isMarkForClarification) {
      if (!isMarkForClarification) {
        this.isMarkForClarification = true;
        const data = {
          userTestSessionQuestionId: UserTestSessionQuestionId,
          serverToken: this.SLToken,
        };
        Mobileapi.QuestionMarkForClarification(data, (response) => {
          if (response.responseCode === 200) {
            this.isMarkForClarification = true;
            window.location.reload();
          }
        });
      } else {
        const data = {
          UserTestSessionQuestionId,
        };
        Mobileapi.assignmentMarkForClarification(data, (response) => {
          if (response.responseCode === 200) {
            this.isMarkForClarification = false;
            window.location.reload();
          }
        });
      }
    },
    revisionQuestionHandler(questionId, userTestSessionQuestionId, isRevision) {
      if (!isRevision) {
        const data = {
          questionData: {
            UserId: Number(this.userId),
            QuestionId: Number(questionId),
            SourceTypeId: 1,
            UserTestSessionQuestionId: Number(userTestSessionQuestionId),
            CreatedBy: Number(this.userId),
          },
          serverToken: this.SLToken,
        };
        Mobileapi.MarkForRevision(data, (response) => {
          if (response.responseCode === 200) {
            window.location.reload();
          }
        });
      } else {
        const data = {
          usertestSessionQuestionId: userTestSessionQuestionId,
        };
        Mobileapi.RemoveRevision(data, (response) => {
          //   console.log(response);
          if (response.responseCode === 200) {
            window.location.reload();
          }
        });
      }
    },
    // removeRevision(userTestSessionQuestionId) {
    //   const data = {
    //     reviseQuestionId: userTestSessionQuestionId,
    //     serverToken: this.SLToken,
    //   };
    //   console.log(data);
    //   Mobileapi.RemoveRevision(data, (response) => {
    //     console.log(response);
    //     if (response.responseCode === 200) {
    //       this.markForRevisionQuestionId = null;
    //       this.isMarkedForRevision = false;
    //     }
    //   });
    // },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap");

* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  -webkit-print-color-adjust: exact;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v139/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

.overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

input {
  position: relative !important;
  opacity: 1 !important;
  pointer-events: visible !important;
}

.material-icons {
  font-size: 16px;
}

.dark-font {
  color: #333333;
}

.light-font {
  color: #676767;
}

.purple-font {
  color: #693383;
}

.blue-font {
  color: #3751ff !important;
}

.green-font {
  color: #16a085;
}

.red-font {
  color: #ea7b7b;
}

.sky-blue-font {
  color: #6eb7fd;
}

.light-purple-font {
  color: #a36efd;
}

.dark-gray-font {
  color: #8e8e8e;
}

.black-font {
  color: #000;
}

.white-font {
  color: #fff !important;
}

.green-bg {
  background-color: #16a085;
}

.red-bg {
  background-color: #ea7b7b;
}

.blue-bg {
  background-color: #3751ff !important;
}

.white-bg {
  background-color: #fff !important;
}

.border-none,
.border-none:active,
.border-none:hover {
  border: none !important;
}

.PS-screen {
  width: 100vw;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.PS-title-box {
  margin-top: 20px;
  margin-bottom: 5px;
}

.PS-title-left {
  display: flex;
  gap: 30px;
  align-items: center;
}

.print-page p {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  color: #3751ff;
}

.PS-screen-inner {
  width: 80%;
  margin: 0 auto;
}

.PS-top {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 20px;
}

/* .PS-top > .summary-box{
    flex: 1 1 50%;
    min-width: 50%;
} */
/* .PS-top > .summary-box{
    flex: 1 1 50%;
    min-width: 50%;
} */

.base-box {
  background-color: #fff;
  border: 1px solid #e0e4f0;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  padding: 20px;
  height: 420px;
}

.summary-box {
  width: 50%;
  flex: 1 1 540px;
  min-width: 540px;
  background: #fff url("../assets/wave-bkg.svg") no-repeat center;
  background-size: cover;
}

.highlights-box {
  width: 30%;
  flex: 1 1 360px;
  min-width: 360px;
}

.subject-box {
  width: 20%;
  flex: 1 1 234px;
  min-width: 234px;
}

.bullet {
  border-radius: 50px;
  width: 15px;
  height: 15px;
  display: block;
}

.skipped-bullet {
  background: #e8e8e8;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(200, 200, 200, 0.3);
}

.correct-bullet {
  background: #00b894;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 184, 148, 0.3);
}

.incorrect-bullet {
  background: #ff7675;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(255, 118, 117, 0.3);
}

.revision-bullet {
  background: #a36efd;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(163, 110, 253, 0.3);
}

.clarification-bullet {
  background: #6eb7fd;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(110, 183, 253, 0.3);
}

.SC-left {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 21px;
  flex: 1 1 35%;
}

.vc-donut-box {
  flex: 1 1 70%;
}

.SC-left p {
  display: flex;
  align-items: center;
  gap: 8px;
}

.summary-content {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

/* .summary-content > * {
    flex: 1;
} */

.cdc {
  width: 200px !important;
  margin-bottom: 200px !important;
}

.donut-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.DC-bottom {
  display: flex;
  gap: 18px;
}

.donutHr {
  display: inline-block;
  width: 1px;
  border: none;
  background-color: #e0e4f0;
  height: 42px;
}

.PS-time {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 15px;
  gap: 5px;
}

.time-light-span {
  /* display: inline-block; */
  display: flex;
  align-items: center;
}

.sub-inner-box {
  width: 100%;
  padding: 15px 0;
}

.TQ-box {
  background-color: #eafffb;
  margin-top: 10px;
}

.AQ-box {
  background-color: #dbfff8;
}

.AT-box {
  background-color: #c6fcf1;
  border-radius: 0px 0px 4px 4px;
}

.sub-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  height: 95%;
}

.gauge-chart-box {
  width: 100%;
  height: 200px;
  width: 200px;
  margin: 0 auto;
  /* margin-top: -55px; */
  position: relative;
}

.guage-value {
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Practice Session Bottom  */
.PS-bottom {
  padding: 0;
  margin-top: 20px;
  height: 100%;
  max-height: 840px;
}

.PS-bottom-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 0;
  border-bottom: 1px solid #e0e4f0;
  gap: 20px;
  position: relative;
}

.PS-bottom-header > * {
  flex: 1;
}

.header-item {
  position: relative;
  padding: 18px 0;
  display: flex;
  align-items: center;
  gap: 11px;
  cursor: pointer;
}

.active-select-question::after {
  position: absolute;
  content: "";
  background: #3751ff;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 4px;
  bottom: 0;
}

.header-item img {
  height: 35px;
  width: 35px;
}

.PS-bottom-body {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.filter-sidebar {
  flex: 1 1 20%;
  height: 100%;
  border-right: 1px solid #e0e4f0;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-item {
  border-top: 1px solid #e0e4f0;
  padding: 10px 0;
}

.filter-heading {
  padding: 15px;
  display: flex;
  align-items: center;
}

.filter-item-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}

.filter-list {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 130px;
  overflow: scroll;
}

.filter-list-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-list label {
  font-weight: 400;
  font-size: 13px;
  color: #000;
}

.question-section {
  flex: 1 1 80%;
  /* width: 80%; */
  background-color: #f3f5fc;
  padding: 0 15px;
}

.QLI-answer {
  border-radius: 4px;
  /* padding: 10px 20px; */
  color: #333;
  display: flex;
  align-items: center;
  gap: 5px;
}

.question-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  /* padding: 10px 20px; */
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  padding: 15px 35px 15px 20px;
  gap: 20px;
}

/* .question-list-item > * {
    flex: 1;
} */

.question-list-item img {
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  display: flex;
  height: 47px;
  width: 100%;
  object-fit: cover;
  object-position: top left;
}

.close-button {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.QS-header {
  display: flex;
  justify-content: space-between;
  padding: 15px 35px 15px 20px;
  gap: 20px;
}

.QS-header p {
  font-size: 12px;
  color: #676767;
  font-weight: 400;
  /* flex: 1; */
}

/* .QS-header>*:nth-child(1) {
    width: 414px;
}

.QS-header>*:nth-child(2) {
    width: 160px;
} */

.question-list-inner {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 600px;
  overflow: auto;
}

::-webkit-scrollbar {
  display: none;
}

.question-list-inner::-webkit-scrollbar {
  display: none;
}

.conceptText-truncate {
  max-width: 25ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.donut-content p {
  font-size: 8px !important;
  font-weight: 600 !important;
}

.donut-content span {
  font-size: 1.5rem !important;
}

@media screen and (max-width: 1300px) {
  .donut-content p {
    font-size: 0.8rem !important;
  }

  .donut-content span {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 776px) {
  .filter-btn {
    border: 2px solid #3751ff;
    border-left: 0;
    border-radius: 0 20px 20px 0;
    width: 160px !important;
    height: 40px;
    cursor: pointer;
    line-height: 38px;
    padding: 0 0 0 15px;
  }

  .mobile-filter-body {
    position: relative;
  }

  .filter-sidebar {
    /* transform: translateX(0%) !important; */
  }

  .sidebar-content {
    /* flex-direction: column !important; */
  }

  .filter-sidebar-minimize {
    position: absolute;
    background: white;
    flex-direction: column;
    /* transform: translateX(-100%); */
    transition: 0.5s all ease-in;
  }

  /* .filter-heading-minimize{
    padding: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    left: 195%;
    background: white;
    width: 160px !important;
    border-radius: 0 20px 20px 0;
  } */
}

.hide-on-mobile {
  display: block !important;
}

.hide-on-desktop {
  display: none !important;
}

@media screen and (max-width: 430px) {
  .hide-on-mobile {
    display: none !important;
  }

  .hide-on-desktop {
    display: block !important;
  }

  .donut-content p {
    font-size: 0.6rem !important;
  }

  .donut-content span {
    font-size: 1.125rem !important;
  }

  .PS-title-box p {
    font-size: 16px !important;
  }

  .base-box-title {
    font-size: 14px !important;
  }

  .highlights-box .base-box-title {
    margin-bottom: 17px !important;
  }

  .PS-top[data-v-47908213] {
    gap: 16px;
  }

  .base-box {
    padding: 14px 12px;
  }

  .sub-inner-box {
    width: 200px;
    margin: 0 auto;
  }

  .PS-bottom-header {
    padding: 0;
    gap: 8px;
    display: grid;
    grid-template-columns: auto auto auto;
    border: none;
  }

  .responsive-mobile {
    min-width: 100% !important;
  }

  .PS-screen-inner {
    width: 90%;
  }

  .header-item img {
    height: 24px;
    width: 24px;
  }

  .header-item p {
    font-size: 12px !important;
  }

  .header-item span {
    font-size: 10px !important;
  }

  .header-item {
    gap: 5px;
  }

  .filter-sidebar {
    display: flex;
    flex-direction: column;
    border: none;
  }

  .sidebar-content {
    flex-direction: column !important;
  }

  .filter-list {
    position: unset !important;
    box-shadow: none !important;
  }

  .filter-item {
    background-color: #fff !important;
    border: none !important;
    width: 100% !important;
    border-bottom: 1px solid #e0e4f0 !important;
  }

  .chapter-title,
  .concept-title,
  .difficulty-title {
    width: 50%;
  }

  .PS-bottom {
    max-height: 100%;
  }

  .question-section {
    padding: 0;
  }

  .QS-header {
    padding: 17px 0;
    gap: 7px;
  }

  .question-list-item {
    flex-direction: column;
    padding: 18px !important;
  }

  .difficulty-btn {
    padding: 5px 10px;
  }

  .conceptText-truncate {
    max-width: 100%;
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
  }

  .highlights-box {
    height: 100%;
  }

  .PS-bottom {
    margin-top: 15px;
  }

  .close-button,
  .print-page {
    opacity: 0;
    pointer-events: none;
  }

  .question-header p {
    font-size: 14px !important;
  }

  .material-icons {
    font-size: 14px !important;
  }

  .modal-close {
    padding: 3px !important;
  }

  .concept-index-btn {
    padding: 5px 10px !important;
    font-size: 14px !important;
  }

  .question-header {
    gap: 10px !important;
  }
}

@media screen and (max-width: 370px) {
  .header-item p {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1470px) {
  .PS-top > *:nth-child(1) {
    flex: 1 1 100% !important;
  }

  .PS-top > *:nth-child(2) {
    flex: 1 1 48% !important;
  }

  .PS-top > *:nth-child(3) {
    flex: 1 1 48% !important;
  }

  .vc-donut-box {
    flex: 1 1 10%;
  }
}

@media screen and (max-width: 1250px) {
  .vc-donut-box {
    flex: 1 1 30%;
  }
}

@media screen and (max-width: 992px) {
  /* .PS-bottom {
        transform: rotate(-90deg);
        transform-origin: 42% 80%;
        width: 1200px;
    } */

  /* .PS-bottom-oriantation {
        padding-left: 150px;
        height: 1240px;
        overflow-x: scroll;
        overflow-y: hidden;
        background-color: red;
    } */
  .filter-heading {
    width: 200px;
  }

  .filter-item {
    position: relative;
    width: 200px;
    background-color: #e0e4f050;
    border: 1px solid #e0e4f0;
  }

  .filter-list {
    position: absolute;
    background-color: #fff;
    box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
    padding: 10px;
    width: 100%;
    top: 59px;
    left: 0;
    z-index: 5;
  }

  .DC-bottom {
    gap: 5px;
  }

  .PS-bottom-body {
    flex-direction: column;
  }

  .filter-sidebar {
    display: flex;
  }

  .sidebar-content {
    display: flex;
    flex-direction: row;
  }

  .summary-content {
    flex-direction: column;
    height: 100%;
    gap: 30px;
  }

  .responsive-mobile {
    min-width: 960px;
  }

  .summary-box {
    /* width: 50%; */
    flex: 1 1 100%;
    min-width: 100%;
    height: 100%;
  }

  .gauge-chart-box {
    /* height: 215px; */
  }

  .SC-left {
    /* flex-direction: row;
        flex-wrap: wrap; */
    gap: 21px;
  }

  .highlights-box {
    flex: 1 1 100%;
    min-width: 100%;
  }

  .subject-box {
    flex: 1 1 100%;
    min-width: 100%;
    /* height: 93%; */
  }
}

/* question modal css */
.question-modal {
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.question-header {
  width: 100%;
  background-color: #f3f5fc;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  gap: 20px;
  align-content: center;
  padding: 20px;
  position: relative;
}

.question-header > * {
  flex: 1;
}

.question-header p {
  color: #333;
  font-size: 16px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.question-modal img {
  width: 100%;
  height: 100%;
}

.question-modal-body {
  padding: 20px;
  max-height: 65vh;
  overflow: scroll;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff7675;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.concept-index-tab {
  display: flex;
  gap: 20px;
}

.concept-index-btn {
  border: 1px solid #d6daf7;
  background: #f8f8f8;
  border-radius: 4px;
  opacity: 0.65;
  cursor: pointer;
  padding: 10px 20px;
  color: #333;
}

.conceptTabActive {
  opacity: 1;
  background: #f0f2ff;
  border: 1px solid #333;
  font-weight: 600;
}

/* mark of revision and clarification css  */
.clarification-element {
  position: relative;
}

.three-dot-icon {
  display: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 20px;
}

.three-dot-icon img {
  width: 13px;
  height: 34px;
}

.question-list-item:hover,
.question-list-item:active {
  border: 1px solid black;
  position: relative;
}

.clarification-element:hover .three-dot-icon,
.clarification-element:active .three-dot-icon {
  display: block !important;
}

.three-dot-content {
  position: absolute;
  background-color: #fff;
  bottom: 0;
  width: 231px;
  right: 25px;
  height: 100%;
  padding: 20px;
  border-radius: 5px 0 0 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #eee;
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
}

.three-dot-item {
  width: 100%;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  background-color: #f3f5fc;
  padding: 5px;
  cursor: pointer;
}

.three-dot-item img {
  width: 18px !important;
  height: 18px !important;
}

.difficulty-btn {
  border-radius: 4px;
  color: #fff;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 11px;
  justify-content: center;
}

.basic-btn {
  background-color: #16a085;
}

.easy-btn {
  background-color: #8bc42e;
}

.normal-btn {
  background-color: #ffbf44;
}

.hard-btn {
  background-color: #ff835c;
}

.tough-btn {
  background-color: #b03e3e;
}

.invisible-scroll {
  overflow-y: scroll;
  max-height: 450px;
}

.invisible-scroll::-webkit-scrollbar {
  display: none;
}

.question-status-icon {
  width: 30px !important;
  height: 30px !important;
  border: none !important;
}

.btn-disabled {
  pointer-events: none;
}

input {
  /* position: relative !important; */
  opacity: 1 !important;
  pointer-events: visible !important;
}

.dark-text {
  color: #333333;
}

.diffLevelTitle {
  font-size: 13px;
  font-weight: 700;
  color: #333333;
  /* padding-left: 14px; */
  margin: 16px 0;
}

.materialIcon {
  font-size: 15px;
  padding-right: 10px;
  padding-top: 4px;
}

.difficultylevelTitle {
  inline-size: 158px;
  overflow-wrap: break-word;
}

/* .difficultylevelTitle.active {
  background: #17af7e;
    color: #fff !important;
} */

.difficultyLevelButton {
  height: 24px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 16px; */
  color: #8e8e8e;
  cursor: pointer;
  max-width: 62px;
  min-width: 49px;
}

.difficultyLevelButton:disabled {
  background-color: #f1f1f1;
  pointer-events: none;
}

.all-btn {
  /* padding-bottom: 16px;
  color: #8E8E8E;
    background: white;
    text-align: center;
    line-height: 22px;
    width: max-content;
    margin-bottom: 5px; */

  padding-bottom: 16px;
  color: #8e8e8e;
  border: none;
  /* background: white; */
  font-weight: 600;
  text-align: center;
  line-height: 22px;
  width: -moz-max-content;
  width: max-content;
  margin-bottom: 5px;
  text-align: left;
  margin-left: 1px;
}

/* .all-btn.disabled{
  background-color: #F1F1F1;
    pointer-events: none;
  color: #8E8E8E;
} */
.all-btn.active {
  /* background: #5161cd;
    color: #fff !important; */
  color: #5161cd !important;
}

.diffGrpButtons {
  padding-bottom: 15px;
  width: 215px;
}

.difficultlevel {
  padding-left: 15px;
}

.question-color-border {
  border-radius: 5px 0px 0px 5px;
  height: 100%;
  left: -1px;
  top: 0;
  position: absolute;
  width: 5px;
  display: block;
}
</style>
